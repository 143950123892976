@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab/mdc-tab";
.statusIcon {
  width: 25px;
  height: 25px;
  .online {
    color: green;
    fill: green;
  }
  .offline {
    color: red;
    fill: red;
  }
}
